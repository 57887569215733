'use client'

import type { PropsWithChildren } from 'react'
import type { Session } from 'next-auth'
import { SessionProvider, signIn } from 'next-auth/react'
import lazyValue from 'lazy-value'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { require, define } from 'loader.js'
import type { JesterConfig } from '@blakeelearning/content-loader-core'
import { LoggerProvider } from '@wl/log'
import { createIDBPersister } from '@/lib/idb-persister'
import { RollbarProvider } from '@wl/rollbar'
import { ResponseError } from '@wl/api-client'
import { InteractiveConfigProvider } from '@/components/content-interactive/content-interactive'
import { logger } from '@/lib/logger'

if (process.env.NEXT_PUBLIC_API_MOCKING) {
  require('../../mocks')
}

function isAborted(error: unknown): error is DOMException {
  return error instanceof DOMException && error.name === 'AbortError'
}

function is401Error(error: unknown): error is ResponseError {
  return error instanceof ResponseError && error.response.status === 401
}

function is403Error(error: unknown): error is ResponseError {
  return error instanceof ResponseError && error.response.status === 403
}

/**
 * Prevents the signIn function from being called multiple times
 * when multiple queries fail with a 401 error.
 */
const signInOnce = lazyValue(() => signIn())

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      onError(error) {
        if (is401Error(error)) {
          void signInOnce()
        }
      },
      retry(failureCount, error) {
        if (is401Error(error) || is403Error(error)) {
          return false
        }

        return failureCount < 3
      },
    },
  },
})

const persister = createIDBPersister()

Object.assign(globalThis, { require, define })

interface ProvidersProps extends PropsWithChildren {
  config: JesterConfig
  jesterFeatures: string[]
  session: Session | null
}

export function Providers({
  config,
  children,
  session,
  jesterFeatures,
}: ProvidersProps) {
  globalThis.Rollbar?.configure({
    checkIgnore(_isUncaught, args, _item) {
      let ignore = false

      for (const arg of args) {
        if (
          // Ignore 401 responses from the API client
          is401Error(arg) ||
          // Ignore aborted fetch requests
          isAborted(arg)
        ) {
          ignore = true
        }
      }

      return ignore
    },
  })

  return (
    <RollbarProvider client={globalThis.Rollbar}>
      <LoggerProvider logger={logger}>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{ persister }}
        >
          <SessionProvider
            session={session}
            refetchInterval={Number(
              process.env.NEXT_PUBLIC_SESSION_REFETCH_INTERVAL,
            )}
            refetchWhenOffline={false}
          >
            <InteractiveConfigProvider
              config={config}
              features={jesterFeatures}
            >
              {children}
            </InteractiveConfigProvider>
          </SessionProvider>

          <ReactQueryDevtools initialIsOpen={false} />
        </PersistQueryClientProvider>
      </LoggerProvider>
    </RollbarProvider>
  )
}
