import { createLogger, prettyLog } from '@wl/log'
import type { Level } from '@wl/log'

const level: Level =
  typeof window === 'undefined'
    ? process.env.LOG_LEVEL
    : process.env.NEXT_PUBLIC_LOG_LEVEL

export const logger = createLogger({
  browser: {
    serialize: true,
    write(object) {
      /**
       * Writes pretty logs to the console in non-production like environments
       */

      if (process.env.NODE_ENV === 'production') {
        // eslint-disable-next-line no-console
        console.log(JSON.stringify(object))
      } else {
        // eslint-disable-next-line no-console
        console.log(...prettyLog(object))
      }
    },
  },
  level,
  base: {
    app: 'student',
    env: process.env.ENVIRONMENT,
    revision: process.env.COMMIT_SHA,
  },
})
