import {
  useRef,
  useEffect,
  useState,
  Fragment,
  createContext,
  useContext,
} from 'react'
import type { PropsWithChildren } from 'react'
import { renderJester } from '@blakeelearning/content-loader-render'
import type {
  ActivityManifestBundle,
  ContentRenderOptions,
  ContentSpec,
  ActivityAccent,
  ContentActions,
  Manifest,
  JesterConfig,
} from '@blakeelearning/content-loader-render'
import { useLogger } from '@wl/log'
import { Transition } from '@headlessui/react'
import Loading from '@/app/loading'

export const InteractiveConfigContext = createContext<{
  config: JesterConfig
  features: string[]
} | null>(null)

interface InteractiveConfigProviderProps extends PropsWithChildren {
  config: JesterConfig
  features?: string[]
}

export function InteractiveConfigProvider({
  config,
  features = [],
  children,
}: InteractiveConfigProviderProps) {
  return (
    <InteractiveConfigContext.Provider value={{ config, features }}>
      {children}
    </InteractiveConfigContext.Provider>
  )
}

interface UseContentInteractiveOptions<Spec extends ContentSpec> {
  contentSpec: Spec
  contentActions: ContentActions<Spec>
  runtimeVariables: Record<string, unknown>
}

export function useContentInteractive<Spec extends ContentSpec>(
  options: UseContentInteractiveOptions<Spec>,
) {
  const [state] = useState(() => options)
  return state
}

interface ContentInteractiveProperties<Spec extends ContentSpec>
  extends Pick<
    ContentRenderOptions<Spec, ContentActions<Spec>>,
    'features' | 'contentActions' | 'contentSpec' | 'runtimeVariables'
  > {
  accent: ActivityAccent
  manifest: ActivityManifestBundle | Manifest
  position?: 'sidebar'
  loadingBgColour?: 'blue' | 'orange' | 'purple' | 'none'
  interactiveName?: string
}

export function ContentInteractive<S extends ContentSpec>({
  accent = 'au',
  position,
  contentActions,
  contentSpec,
  features,
  manifest,
  runtimeVariables,
  loadingBgColour = 'blue',
  interactiveName,
}: ContentInteractiveProperties<S>): JSX.Element {
  const logger = useLogger(
    interactiveName
      ? `content-interactive::${interactiveName}`
      : 'content-interactive',
  )
  const containerElement = useRef<HTMLDivElement | null>(null)
  const interactiveConfig = useContext(InteractiveConfigContext)

  const [uiLoaded, setUiLoaded] = useState(false)

  useEffect(() => {
    let destroy
    if (interactiveConfig && containerElement.current) {
      destroy = renderJester(
        containerElement.current,
        accent,
        interactiveConfig.config,
        {
          logger(name, event, ...rest) {
            logger.debug(rest, `[${String(name)} :: ${event}]`)
          },
          contentActions: {
            ...contentActions,
            readyForUserInput: () => {
              setUiLoaded(true)
            },
          },
          contentSpec,
          features: [...(features ?? []), ...interactiveConfig.features],
          manifests: [manifest],
          runtimeVariables: { ...runtimeVariables },
          onError(reason) {
            logger.error(reason)
          },
        },
      )
    }

    return destroy
  }, [
    accent,
    contentActions,
    contentSpec,
    features,
    logger,
    manifest,
    runtimeVariables,
    interactiveConfig,
  ])

  if (position === 'sidebar') {
    // Don't show the loading animation for the sidebar
    return (
      <div
        className="absolute inset-0"
        ref={containerElement}
        data-testid="lessonSidebarContainer"
      ></div>
    )
  }

  return (
    <>
      <div
        className={`interactive-height ${uiLoaded ? 'visible' : 'invisible'}`}
        ref={containerElement}
        data-testid="canvasContainer"
      ></div>

      <Transition
        as={Fragment}
        show={!uiLoaded}
        enter="transform transition duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transform transition ease-in-out duration-200 "
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute inset-0 flex-1 max-h-screen">
          <Loading colour={loadingBgColour} />
        </div>
      </Transition>
    </>
  )
}
