import type { PropsWithChildren } from 'react'

interface MainProps extends PropsWithChildren {
  colour?: 'blue' | 'orange' | 'purple' | 'green' | 'none'
}

export function Main({ children, colour = 'blue' }: MainProps) {
  if (colour === 'orange') {
    return (
      <main className="bg-[#E7961E] bg-orange-pattern bg-repeat-round bg-[length:300px_150px] flex flex-col min-h-screen">
        {children}
      </main>
    )
  }

  if (colour === 'purple') {
    return (
      <main className="bg-[#A277F6] bg-purple-pattern bg-repeat-round bg-[length:250px] flex flex-col min-h-screen">
        {children}
      </main>
    )
  }

  if (colour === 'blue') {
    return (
      <main className="bg-[#53c1ff] bg-blue-pattern bg-repeat-round bg-[length:250px] flex flex-col min-h-screen">
        {children}
      </main>
    )
  }

  if (colour === 'green') {
    return (
      <main className="bg-[#55A31E] bg-green-pattern bg-repeat-round bg-[length:300px_150px] flex flex-col min-h-screen">
        {children}
      </main>
    )
  }

  return <main className="flex flex-col min-h-screen">{children}</main>
}
