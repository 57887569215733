import { Main } from '@/components/main/main'
import { LoadingFrog } from '@/components/loading/frog'

interface LoadingProps {
  colour?: 'blue' | 'orange' | 'purple' | 'none'
}

function Loading({ colour = 'blue' }: LoadingProps) {
  return (
    <Main colour={colour}>
      <LoadingFrog />
    </Main>
  )
}

export default Loading
